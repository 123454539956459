<template>
  <section id="detail-kegiatan">
    <b-row>
      <b-col
        lg="3"
        cols="12"
      >
        <kegiatan-about :detail-program="dataProgram" />
        <template v-if="show">
          <kegiatan-catatan :detail-program="dataProgram" />
        </template>
        <kegiatan-status />
      </b-col>

      <b-col
        lg="9"
        cols="12"
      >
        <div>
          <b-form-group class="border-none">
            <b-form-radio-group
              id="detail-kegiatan-radios"
              v-model="mode"
              :options="options"
              button-variant="outline-primary"
              size="lg"
              name="radio-btn-outline"
              buttons
            />
          </b-form-group>
        </div>

        <template v-if="mode == 'log'">
          <template v-if="type == 'list'">
            <kegiatan-log />
          </template>
          <template v-else>
            <kegiatan-log-detail />
          </template>
        </template>
        <template v-else>
          <detail-infrastruktur v-if="dataProgram.jenis_pengadaan_id == '2'" />
          <detail-aplikasi v-if="dataProgram.jenis_pengadaan_id == '3'" />
          <detail-data v-if="dataProgram.jenis_pengadaan_id == '6'" />
          <detail-non v-if="dataProgram.jenis_pengadaan_id == '7'" />
        </template>
      </b-col>

    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BFormRadioGroup, BFormGroup,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'

import KegiatanAbout from './detail-kegiatan/KegiatanAbout.vue'
import KegiatanCatatan from './detail-kegiatan/KegiatanCatatan.vue'
import KegiatanStatus from './detail-kegiatan/KegiatanStatus.vue'
import KegiatanLog from './detail-kegiatan/KegiatanLog.vue'

import DetailInfrastruktur from './detail/DetailKegiatanInfrastruktur.vue'
import DetailAplikasi from './detail/DetailKegiatanAplikasi.vue'
import DetailData from './detail/DetailKegiatanData.vue'
import DetailNon from './detail/DetailKegiatanNon.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormRadioGroup,
    BFormGroup,

    KegiatanAbout,
    KegiatanCatatan,
    KegiatanStatus,
    KegiatanLog,

    DetailInfrastruktur,
    DetailAplikasi,
    DetailData,
    DetailNon,
  },
  data() {
    return {
      userData: getUserData(),
      mode: 'detail',
      type: 'list',
      show: false,
      options: [
        { text: 'Program', value: 'detail' },
        // { text: 'Log Perubahan', value: 'log' },
      ],
      dataProgram: {
        utama: {},
        program: {},
        dokumen: {},
        data_info: {},
        layanan: {},
        aplikasi: {},
        infra: {},
        keamanan: {},
      },
    }
  },
  beforeRouteEnter(to, from, next) {
    if (!to.params.id) {
      next('/')
    } else {
      next()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$http.get('/clearance/core-data', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.$route.params.id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.dataProgram = res.data.data
            if (this.dataProgram.status < 12) {
              this.show = true
            }
          }
        })
    })
  },
}
</script>
